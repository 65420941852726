var Aifs;

(function (Aifs) {

    function OAuth() {
    }

    OAuth.popup = function (endpoint, provider, opts, callback) {
        var wnd, frm, wndTimeout;
        //if ( ! config.key)
        //    return callback(new Error('OAuth object must be initialized'));
        if (arguments.length == 3) {
            callback = opts;
            opts = {};
        }

        //if (cacheEnabled(opts.cache)) {
        //    var res = tryCache(provider, opts.cache);
        //    if (res)
        //        return callback(null, res);
        //}

        //if ( ! opts.state) {
        //    opts.state = create_hash();
        //    opts.state_type = "client";
        //}
        //client_states.push(opts.state);

        var url = endpoint;

        //var url = config.oauthd_url + '/auth/' + provider + "?k=" + config.key;
        //url += '&d=' + encodeURIComponent(getAbsUrl('/'));
        //if (opts)
        //    url += "&opts=" + encodeURIComponent(JSON.stringify(opts));


        // create popup
        var wnd_settings = {
            width: Math.floor(window.outerWidth * 0.8),
            height: Math.floor(window.outerHeight * 0.5)
        };
        if (wnd_settings.height < 350)
            wnd_settings.height = 350;
        if (wnd_settings.width < 800)
            wnd_settings.width = 800;
        wnd_settings.left = window.screenX + (window.outerWidth - wnd_settings.width) / 2;
        wnd_settings.top = window.screenY + (window.outerHeight - wnd_settings.height) / 8;
        var wnd_options = "width=" + wnd_settings.width + ",height=" + wnd_settings.height;
        wnd_options += ",toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0";
        wnd_options += ",left=" + wnd_settings.left + ",top=" + wnd_settings.top;

        opts = { provider: provider, cache: opts.cache };

        function getMessage(e) {
            if (endpoint.indexOf(e.origin) != 0)
                return;
            try { wnd.close(); } catch (e) { }
            //opts.data = e.data;
            return opts.callback(null, { provider: opts.provider, data: e.data });
        }
        opts.callback = function (e, r) {
            if (window.removeEventListener)
                window.removeEventListener("message", getMessage, false);
            else if (window.detachEvent)
                window.detachEvent("onmessage", getMessage);
            else if (document.detachEvent)
                document.detachEvent("onmessage", getMessage);
            opts.callback = function () { };
            if (wndTimeout) {
                clearTimeout(wndTimeout);
                wndTimeout = undefined;
            }
            return callback(e, r);
        };

        if (window.attachEvent)
            window.attachEvent("onmessage", getMessage);
        else if (document.attachEvent)
            document.attachEvent("onmessage", getMessage);
        else if (window.addEventListener)
            window.addEventListener("message", getMessage, false);
        if (typeof chrome != 'undefined' && chrome.runtime && chrome.runtime.onMessageExternal)
            chrome.runtime.onMessageExternal.addListener(function (request, sender, sendResponse) {
                request.origin = sender.url.match(/^.{2,5}:\/\/[^/]+/)[0];
                return getMessage(request);
            });

        //if (! frm && (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0)) {
        //    frm = document.createElement("iframe");
        //    frm.src = config.oauthd_url + "/auth/iframe?d=" + encodeURIComponent(getAbsUrl('/'));
        //    frm.width = 0
        //    frm.height = 0
        //    frm.frameBorder = 0
        //    frm.style.visibility = "hidden";
        //    document.body.appendChild(frm);
        //}

        wndTimeout = setTimeout(function () {
            opts.callback(new Error('Authorization timed out'));
            try { wnd.close(); } catch (e) { }
        }, 1200 * 1000);
        wnd = window.open(url, "Authorization", wnd_options);
        if (wnd)
            wnd.focus();
        else
            opts.callback(new Error("Could not open a popup"));
    };

    Aifs.OAuth = OAuth;

})(Aifs || (Aifs = {}));